<template>
  <b-row>
    <b-col lg="12">
      <section class="d-flex justify-content-center flex-wrap mb-2">
        <div
          v-for="status in wholeStatus"
          :key="status"
          class="status-block"
        >
          <feather-icon
            icon="TruckIcon"
            :stroke="status.color"
            size="30"
          />
          <p class="status-title">
            {{ isRight ? status.arabicName : status.englishName }}
          </p>
          <p class="status-counter">
            {{ getStatusCounterBasedOnStatusId(status.id) }}
          </p>
        </div>
      </section>
    </b-col>
    <b-col
      v-for="bus in buses"
      :key="bus"
      lg="3"
      md="4"
      sm="6"
      xs="12"
    >
      <bus-card
        :all-buses="buses"
        :selected-bus="bus"
        :students="studentsWithBuses"
        @status-changed="getBuses()"
      />
    </b-col>
  </b-row>
</template>

<script>
import busCard from '@core/components/bus-card/busCard.vue';
import { busStatus } from '@/libs/acl/Lookups'

export default {
  components: {
    busCard
  },

  data() {
    return {
      buses: [],
      studentsWithBuses: [],
      wholeStatus: busStatus,
      statusCounter: [{
        statusId: '',
        counter: 0
      }]
    }
  },

  mounted() {
    this.getBuses();
  },

  methods: {
    setStatusCounterForAllBuses() {
      this.statusCounter = []
      this.wholeStatus.forEach(status => {
        const currentBusStatus = this.buses.filter((val) => val.status === status.id)
        this.statusCounter.push({ statusId: status.id, counter: currentBusStatus.length });
      });
    },

    getStatusCounterBasedOnStatusId(statusId) {
      const currentStatus = this.statusCounter.find((val) => val.statusId === statusId)
      return currentStatus
           ? currentStatus.counter
           : 0;
    },

    getBuses() {
      this.get({ url: 'Buses/GetAccomodations' }).then((data) => {
        this.buses = data;
        this.setStatusCounterForAllBuses();
      });
      this.get({ url: 'Students/getSubscribedStudentsInBus' }).then((data) => {
        this.studentsWithBuses = data;
      });
    }
  }
}
</script>

<style>
.status-block {
  display: flex;
  margin-right: 1rem;
  margin-top: 1rem;
  border: 1px solid #c3bec6;
  padding: 10px 10px 0;
  background-color: #FFF;
}

.status-title {
  margin-left: 5px;
  margin-top: 5px;
}

.status-counter {
  border-radius: 40%;
  border: 1px solid #c3bec6;
  padding: 3px 10px;
  margin-left: 7px;
}
</style>
